import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NeoBrutalistModal from './NeoBrutalistModal';
import NeoBrutalistInput from './NeoBrutalistInput';
import NeoBrutalistButton from './NeoBrutalistButton';
import { FaSave, FaPlay } from 'react-icons/fa';

const OpenAIKeyModal = ({ isOpen, onClose, onSave, freeGenerationsLeft }) => {
  const { t } = useTranslation();
  const [openaiKey, setOpenaiKey] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    if (openaiKey.length < 8) {
      setError(t('OpenAI API key must be at least 8 characters long'));
      return;
    }
    setIsLoading(true);
    try {
      const isValid = await onSave(openaiKey);
      if (isValid) {
        onClose();
      } else {
        setError(t('Invalid OpenAI API key'));
      }
    } catch (err) {
      setError(t('Error saving OpenAI API key'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <NeoBrutalistModal
      isOpen={isOpen}
      onClose={freeGenerationsLeft > 0 ? onClose : undefined}
      title={t('Enter your OpenAI API Key')}
    >
      <p className="mb-4">{t('You need to enter your OpenAI API key to use this incredible app.')}</p>
      {freeGenerationsLeft > 0 && (
        <p className="mb-4">{t('You have {{count}} free generations left.', { count: freeGenerationsLeft })}</p>
      )}
      <NeoBrutalistInput
        type="password"
        value={openaiKey}
        onChange={(e) => {
          setOpenaiKey(e.target.value);
          setError('');
        }}
        placeholder={t('Enter your OpenAI API key')}
        className="mb-4"
        error={error}
      />
      <div className="flex justify-between">
        {freeGenerationsLeft > 0 && (
          <NeoBrutalistButton onClick={onClose} style="secondary">
            <FaPlay className="mr-2" />
            {t('Try the app first')}
          </NeoBrutalistButton>
        )}
        <NeoBrutalistButton onClick={handleSave} className="mr-2" disabled={isLoading}>
          <FaSave className="mr-2" />
          {isLoading ? t('Saving...') : t('Save')}
        </NeoBrutalistButton>
      </div>
    </NeoBrutalistModal>
  );
};

export default OpenAIKeyModal;