import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { UserIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NeoBrutalistInput from '../components/NeoBrutalistInput';
import NeoBrutalistButton from '../components/NeoBrutalistButton';
import logger from '../utils/logger';
import Header from '../components/Header';
import Footer from '../components/Footer';




const RegistrationPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorEmailMsg, setErrorEmailMsg] = useState('');
    const [errorPasswordMsg, setErrorPasswordMsg] = useState('');

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        if (!validateEmail(email)) {
            setErrorEmailMsg(t('Invalid email address'));
            return;
        }

        if (password !== confirmPassword) {
            setErrorPasswordMsg(t('Passwords do not match'));
            return;
        }

        if (password.length < 6) {
            setErrorPasswordMsg(t('Password must be at least 6 characters long'));
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, {
                email,
                password
            });
            logger.log('Registration response:', response.data);

            navigate('/login', { state: { notification: t('A verification email has been sent. Please check your email to confirm your account.') } });
        } catch (error) {
            logger.error('Registration error:', error);
            if(error.response && error.response.data && error.response.data.message) {
                setErrorMessage(t(error.response.data.message));    
            } else {
                setErrorMessage(t('Registration failed, please try later'));
            }
        }
    }


    return (
        <>
        <div className="min-h-screen bg-gray-100 flex flex-col ">
        <Header />
        <div className="flex-1 bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-lg shadow-2xl border-4 border-black">
            <div>
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    {t('Register')}
                </h2>
            </div>
            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                    <NeoBrutalistInput
                        label={t('Email')}
                        name="email"
                        type="email"
                        value={email}
                        error={errorEmailMsg}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <NeoBrutalistInput
                        label={t('Password')}
                        name="password"
                        type="password"
                        value={password}
                        error={errorPasswordMsg}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="mt-4">&nbsp;</div>
                    <NeoBrutalistInput
                        label={t('Confirm password')}
                        name="confirm-password"
                        type="password"
                        value={confirmPassword}
                        error={errorPasswordMsg}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>
                {errorMessage && (
                    <div className="text-red-500 bg-red-100 border-2 border-red-500 p-4 rounded-lg shadow-2xl font-bold">
                        {t(errorMessage)}
                    </div>
                )}
                <div>
                    <NeoBrutalistButton
                        type="submit"
                        style="primary"
                        className="w-full"
                    >
                        <UserIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                        {t('Register')}
                    </NeoBrutalistButton>
                </div>
            </form>
            <div className="text-center">
                <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                    {t('You already have an account ? Log in')}
                </Link>
            </div>
        </div>
    </div>
    <Footer />
    </div>
    </>
    
    );
};

export default RegistrationPage;