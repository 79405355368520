import React from 'react';
import { Link } from 'react-router-dom';

const HeaderLink = ({ to, children, active }) => {
  return (
    <Link 
      to={to}
      className={`relative px-4 py-2 text-black font-extrabold text-xl uppercase tracking-wider border-b-4 border-black hover:border-b-0 transition-all duration-150 ease-in-out after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-full after:h-1 ${active ? 'after:bg-black after:translate-y-0 after:h-full text-white' : 'after:bg-yellow-400'} after:transform after:translate-y-1 hover:after:translate-y-0 hover:after:h-full after:transition-all after:duration-150 after:ease-in-out hover:text-white`}
    >
      <span className="relative z-10">{children}</span>
    </Link>
  );
};

export default HeaderLink;