import React, { createContext, useState, useEffect, useContext } from 'react';
import { axiosInstance } from '../axiosConfig';
import axios from 'axios';



const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
       // const response =  await axiosInstance.post(`${process.env.REACT_APP_API_URL}/auth/verify-token?token=${token}`);
        const response =  await axios.post(`${process.env.REACT_APP_API_URL}/auth/verify-token`, { token: token });
        if(response.status === 200) {
          setIsAuthenticated(true);
        } else {
          console.error('Token invalide:', token);
          localStorage.removeItem('token');
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Token invalide:', error);
        localStorage.removeItem('token');
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
    setIsLoading(false);
  };

  const login = (token) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading, login, logout, checkAuthStatus }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);