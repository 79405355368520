import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import NeoBrutalistModal from "./NeoBrutalistModal";
import NeoBrutalistInput from "./NeoBrutalistInput";
import NeoBrutalistButton from "./NeoBrutalistButton";
import {
  PaperAirplaneIcon,
  PlusIcon,
  ClipboardIcon,
} from "@heroicons/react/solid";
import logger from "../utils/logger";

const SendExerciseModal = ({ isOpen, onClose, exercise, onExerciseSent }) => {
  const { t } = useTranslation();
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [newStudentEmail, setNewStudentEmail] = useState("");
  const [newStudentFirstName, setNewStudentFirstName] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);

  const [sentToStudents, setSentToStudents] = useState([]);
  const [uniqueLinks, setUniqueLinks] = useState({});
  const [showNotification, setShowNotification] = useState(false);
  const [showSentNotification, setShowSentNotification] = useState(false);

  useEffect(() => {
    if (isOpen) {
      fetchStudents();
    }
  }, [isOpen]);

  const fetchStudents = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/students`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          params: { exerciseId: exercise._id },
        }
      );
      setStudents(response.data.students);
      setSentToStudents(response.data.sentToStudents.map((se) => se.studentId));
      setUniqueLinks(
        response.data.sentToStudents.reduce(
          (acc, se) => ({ ...acc, [se.studentId]: se.uniqueLink }),
          {}
        )
      );
    } catch (error) {
      logger.error("Erreur lors de la récupération des étudiants:", error);
    }
  };

  const handleStudentSelection = (studentId) => {
    setSelectedStudents((prev) =>
      prev.includes(studentId)
        ? prev.filter((id) => id !== studentId)
        : [...prev, studentId]
    );
  };

  const handleAddStudent = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(newStudentEmail)) {
      setEmailError(true);
      return;
    }
    if (!newStudentFirstName.trim()) {
      setFirstNameError(true);
      return;
    }

    setEmailError(false);
    setFirstNameError(false);

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/students/invite`,
        { email: newStudentEmail, firstName: newStudentFirstName },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setNewStudentEmail("");
      setNewStudentFirstName("");
      fetchStudents();
    } catch (error) {
      logger.error("Erreur lors de l'ajout de l'étudiant:", error);
    }
  };

  const handleSendExercise = async () => {
    if (selectedStudents.length === 0) {
      logger.error("Erreur: Aucun étudiant sélectionné.");
      onExerciseSent(exercise._id, selectedStudents);
      return;
    }
    onClose();
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/exercises/${exercise._id}/send`,
        { studentIds: selectedStudents },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      // Mettre à jour sentToStudents après un envoi réussi
      setSentToStudents((prevSent) => [...prevSent, ...selectedStudents]);
      setSelectedStudents([]); // Réinitialiser les étudiants sélectionnés
      setShowSentNotification(true); // Afficher la notification
      setTimeout(() => setShowSentNotification(false), 3000); // Cacher la notification après 3 secondes
     
    } catch (error) {
      logger.error("Erreur lors de l'envoi de l'exercice:", error);
    }
  };

  const handleCopyLink = (studentId) => {
    const studentExercise = sentToStudents.find((se) => se === studentId);
    if (studentExercise) {
      const linkToken = uniqueLinks[studentId];
      const link = `${window.location.origin}/exercise-reply/${linkToken}`;
      logger.log("link", link);
      navigator.clipboard.writeText(link);
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
    }
  };

  return (
    <>
      <NeoBrutalistModal
        isOpen={isOpen}
        onClose={onClose}
        title={t("Send Exercise")}
      >
        <div className="space-y-4">
          <h3 className="text-xl font-bold">{t("Select Students")}</h3>
          {students.length === 0 ? (
            <p className="text-lg">{t("No students found")}</p>
          ) : (
            <>
              <p className="text-lg">
                {t("Total sent")}: {sentToStudents.length}
              </p>

              <div className="max-h-60 overflow-y-auto border-4 border-black p-4 rounded-lg bg-gray-100">
                {students.map((student) => (
                  <div
                    key={student._id}
                    className="flex items-center space-x-4 p-2 border-b border-gray-300"
                  >
                    <input
                      type="checkbox"
                      id={`student-${student._id}`}
                      checked={selectedStudents.includes(student._id)}
                      onChange={() => handleStudentSelection(student._id)}
                      className="h-6 w-6 cursor-pointer accent-black"
                      //   disabled={sentToStudents.includes(student._id)}
                    />
                    <label
                      htmlFor={`student-${student._id}`}
                      className={`text-lg font-bold ${
                        sentToStudents.includes(student._id)
                          ? "text-gray-500"
                          : "text-black"
                      }`}
                    >
                      {student.firstName}{" "}
                      <small className="text-gray-500">({student.email})</small>
                      {sentToStudents.includes(student._id) && (
                        <span className="ml-2 text-sm text-yellow-500">
                          ({t("Already sent")})
                        </span>
                      )}
                    </label>
                    <div className="flex-1"></div>
                    <div className="ml-auto">
                      {sentToStudents.includes(student._id) && (
                        <NeoBrutalistButton
                          onClick={() => handleCopyLink(student._id)}
                          style="secondary"
                          size="small"
                        >
                          <ClipboardIcon className="h-5 w-5 mr-2" />
                          {t("Copy Link")}
                        </NeoBrutalistButton>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          <div className="flex justify-between flex-col md:flex-row gap-4">
            <div className="flex md:space-x-2 md:h-14 flex-col md:flex-row ">
              <div style={{ position: "relative", top: -4 }}>
                <NeoBrutalistInput
                  value={newStudentFirstName}
                  onChange={(e) => setNewStudentFirstName(e.target.value)}
                  error={firstNameError}
                  errorMessage={t("Name is required")}
                  placeholder={t("Student name")}
                />
              </div>
              <div style={{ position: "relative", top: -4 }}>
                <NeoBrutalistInput
                  value={newStudentEmail}
                  onChange={(e) => setNewStudentEmail(e.target.value)}
                  error={emailError}
                  errorMessage={t("Invalid email")}
                  placeholder={t("Student email")}
                />
              </div>
              <NeoBrutalistButton
                onClick={handleAddStudent}
                style="secondary"
                size="small"
              >
                <PlusIcon className="h-6 w-6" />
                {t("Add")}
              </NeoBrutalistButton>
            </div>
            <NeoBrutalistButton onClick={handleSendExercise} style="primary">
              <PaperAirplaneIcon className="h-6 w-6" />
              {t("Send Exercise")}
            </NeoBrutalistButton>
          </div>
        </div>
      </NeoBrutalistModal>
      {showNotification && (
        <div className="fixed bottom-4 right-4 bg-yellow-400 text-black font-bold px-4 py-2 rounded-lg border-4 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
          {t("Link copied to clipboard!")}
        </div>
      )}
      {showSentNotification && (
        <div className="fixed bottom-4 right-4 bg-green-400 text-black font-bold px-4 py-2 rounded-lg border-4 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
          {t("Exercise sent successfully!")}
        </div>
      )}
    </>
  );
};

export default SendExerciseModal;
