import React from 'react';

const Loader = ({title, description}) => {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="relative w-32 h-32 animate-spin">
        <div className="absolute w-full h-full border-8 border-yellow-400 rounded-full"></div>
        <div className="absolute w-full h-full border-8 border-black border-t-transparent rounded-full animate-spin"></div>
      </div>
      <div className="mt-4 text-2xl font-bold text-black">
        {title}
      </div>
      <div className="mt-2 text-lg font-semibold text-gray-700 text-center">
        {description}
      </div>
    </div>
  );
};

export default Loader;