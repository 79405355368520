import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-white border-t-4 border-black shadow-[0_-4px_0px_0px_rgba(0,0,0,1)] p-4">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        <div className="text-black font-bold mb-2 md:mb-0">
          © {currentYear} {process.env.REACT_APP_NAME} <i>by {' '}
          <Link to="https://loopus.tech" target="_blank" className="text-yellow-500 hover:text-yellow-400 transition-colors">Loopus</Link>
          </i>
        </div>
       <nav className="flex flex-col md:flex-row md:space-x-2 text-sm">
          <a href="/terms" className="text-black hover:text-yellow-400 transition-colors block md:inline-block p-2 text-left">
            {t('Terms')}
          </a>
          <a href="/privacy" className="text-black hover:text-yellow-400 transition-colors block md:inline-block p-2 text-left">
            {t('Privacy')}
          </a>
          <a href="/legal" className="text-black hover:text-yellow-400 transition-colors block md:inline-block p-2 text-left">
            {t('Legal notice')}
          </a>
          <a href="/contact" className="text-black hover:text-yellow-400 transition-colors block md:inline-block p-2 text-left">
            {t('Contact')}
          </a>
        </nav> 
      </div>
    </footer>
  );
};

export default Footer;