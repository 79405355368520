import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Header from "../components/Header";
import NeoBrutalistButton from "../components/NeoBrutalistButton";
import {
  PencilIcon,
  TrashIcon,
  DownloadIcon,
  PaperAirplaneIcon,
  ClipboardCheckIcon,
} from "@heroicons/react/solid";
import ExerciseEditor from "../components/ExerciseEditor";
import { useLocation, useNavigate } from "react-router-dom";
import SendExerciseModal from "../components/SendExerciseModal"; // Nouveau composant à créer
import ExerciseCorrection from "../components/ExerciseCorrection";
import NeoBrutalistNotification from "../components/NeoBrutalistNotification";
import { axiosInstance } from "../axiosConfig";
import Footer from "../components/Footer";
import logger from "../utils/logger";

const MyExercisesPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [exercises, setExercises] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingExercise, setEditingExercise] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const deleteButtonRef = useRef(null); // Create a ref for the delete button
  const [sendModalOpen, setSendModalOpen] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [isCorrecting, setIsCorrecting] = useState(false);
  const [correctingExercise, setCorrectingExercise] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  useEffect(() => {
    // Focus on the delete button when delete confirmation is shown
    if (deleteConfirmation) {
      deleteButtonRef.current?.focus();
      const handleKeyDown = (e) => {
        if (e.key === "Enter") {
          confirmDelete();
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [deleteConfirmation]);

  useEffect(() => {
    fetchExercises();
  }, []);

  useEffect(() => {
    // Vérifier si un nouvel exercise a été créé
    if (location.state && location.state.newExerciseId) {
      const newExerciseId = location.state.newExerciseId;
      handleEdit(exercises.find((ex) => ex._id === newExerciseId));
      // Nettoyer l'état de location pour éviter de rouvrir l'éditeur lors des rechargements
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location, exercises]);

  const fetchExercises = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/exercises/my-exercises`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setExercises(response.data);
      if(correctingExercise){
        setCorrectingExercise(response.data.find(ex => ex._id === correctingExercise._id));
      }
    } catch (error) {
      logger.error("Erreur lors de la récupération des exercises:", error);
    }
  };
  const handleEdit = (exercise) => {
    setEditingExercise(exercise);
    setIsEditing(true);
  };
  const handleSaveEdit = async (editedExercise) => {
    try {
      await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/exercises/${editedExercise._id}`,
        editedExercise,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setIsEditing(false);
      setEditingExercise(null);
      fetchExercises();
    } catch (error) {
      logger.error("Erreur lors de la mise à jour de l'exercise:", error);
    }
  };
  const handleCloseEdit = () => {
    setIsEditing(false);
    setEditingExercise(null);
  };
  const handleDelete = (id) => {
    setDeleteConfirmation(id);
  };

  const confirmDelete = async () => {
    if (deleteConfirmation) {
      try {
        await axiosInstance.delete(
          `${process.env.REACT_APP_API_URL}/exercises/${deleteConfirmation}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        fetchExercises();
        setDeleteConfirmation(null);
      } catch (error) {
        logger.error("Erreur lors de la suppression de l'exercise:", error);
      }
    }
  };

  const cancelDelete = () => {
    setDeleteConfirmation(null);
  };

  const handleDownload = async (exercise) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/exercises/${exercise._id}/download`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      const formattedFileName = exercise.title
      .replace(/[éèêëÉÈÊË]/g, "e")
      .replace(/[îïÎÏ]/g, "i")
      .replace(/[àâÀÂ]/g, "a")
      .replace(/[ôÔ]/g, "o")
      .replace(/[ûùÛÙ]/g, "u")
      .replace(/[çÇ]/g, "c")
      .replace(/[^a-z0-9]/gi, "_")
      .toLowerCase();
      link.download = `${formattedFileName}.pdf`;
      link.click();
    } catch (error) {
      logger.error("Erreur lors du téléchargement du PDF:", error);
    }
  };

  const handleSend = (exercise) => {
    setSelectedExercise(exercise);
    setSendModalOpen(true);
  };

  const handleExerciseSent = (exerciseId, sentToStudents) => {
    // Mettre à jour l'état local si nécessaire
    logger.log(
      `Exercise ${exerciseId} sent to ${sentToStudents.length} students`
    );
    // Vous pouvez également rafraîchir la liste des exercises ici si nécessaire
    fetchExercises();
  };
  const handleCorrection = (exercise) => {
    setCorrectingExercise(exercise);
    setIsCorrecting(true);
  };

  const handleAllCorrected = () => {
    setNotificationMessage(t("All replies have been corrected"));
    setShowNotification(true);
    fetchExercises();
  };

  const handleAutoCorrected = () => {
    setNotificationMessage(t("The exercise has been auto-corrected"));
    setShowNotification(true);
    fetchExercises();
  };

  const handleCloseSendModal = () => {
    setSendModalOpen(false);
    setSelectedExercise(null);
    fetchExercises();
  };

  const getExoTypeName= (type)=>{
    if (type === "fill_in_the_blanks") {
      return t( "Fill in the blanks");
    } else if (type === "matching") {
      return t("Matching");
    } else if (type === "multiple_choice") {
      return t("Multiple choice");
    } else if (type === "true_false") {
      return t("True/False");
    } else if (type === "flashcards") {
      return t("Flashcards");
    } else if (type === "free_writing") {
      return t("Free Writing");
    }
  }

  return (
    <>
<Header activeLink="my-exercises" />
<div className="min-h-screen bg-gray-200 px-4 py-8">
      <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl md:text-4xl font-bold mb-8 text-center text-black border-b-4 border-black pb-2">
      {t("My Exercises")} ({exercises.length})
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-16">
          {exercises.map((exercise) => (
            <div
              key={exercise._id}
              className="bg-white border-4 border-black rounded-none shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] p-6 transition-all duration-300 relative"
            >
              <div className="absolute -top-3 -right-3 transform rotate-3 z-10">
                <div className="bg-yellow-300 border-2 border-black px-2 py-2 text-lg font-bold shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
                  {t(exercise.subject)}
                </div>
              </div>
              <div
                className="absolute top-2 left-2 bg-white border-2 border-black rounded-full p-1 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] transition-all duration-200 cursor-pointer"
                onClick={() => handleDelete(exercise._id)}
              >
                <TrashIcon className="h-6 w-6 text-red-500" />
              </div>
              <img
                src={
                  exercise.illustration
                    ? exercise.illustration
                    : process.env.REACT_APP_URL +
                      "/img/default_illustration.webp"
                }
                alt={exercise.title}
                className="w-full h-32 object-cover mb-4"
              />
              <h2 className="text-2xl font-bold mb-4 text-black h-8 overflow-hidden">
                {exercise.title}
              </h2>
              <p className="text-gray-700 mb-4 md:h-24 overflow-y-auto text-sm">
                {exercise.description}
              </p>
              <div className="bg-gray-300 border-2 border-black p-2 px-4 rounded-md mb-4">
                <span className="font-bold">{t("Type of Exercise")}: </span>
                {getExoTypeName(exercise.type)}
              </div>
              <div className="flex flex-col lg:flex-row justify-between gap-2">
                <NeoBrutalistButton
                  onClick={() => handleEdit(exercise)}
                  style="secondary"
                  size="small"
                  className="flex-1"
                >
                  <PencilIcon className="h-6 w-6" />
                  {t("Edit")}
                </NeoBrutalistButton>
                <NeoBrutalistButton
                  onClick={() => handleDownload(exercise)}
                  style="secondary"
                  size="small"
                  className="flex-1"
                >
                  <DownloadIcon className="h-6 w-6" />
                  {t("Download")}
                </NeoBrutalistButton>
                <NeoBrutalistButton
                  onClick={() => handleSend(exercise)}
                  style={exercise.sentToStudents ? "secondary" : "primary"}
                  size="small"
                  className="flex-1"
                >
                  <PaperAirplaneIcon className="h-6 w-6" />
                  {t("Send")}
                </NeoBrutalistButton>
                {exercise.sentToStudents && (
                  <NeoBrutalistButton
                    onClick={() => handleCorrection(exercise)}
                    style="primary"
                    size="small"
                    className="flex-1"
                  >
                    <ClipboardCheckIcon className="h-4 w-4 mr-2" />
                    {t("Corrections")}
                  </NeoBrutalistButton>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {deleteConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg border-4 border-black shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
            <h2 className="text-2xl font-bold mb-4">{t("Confirm Deletion")}</h2>
            <p className="mb-4">
              {t("Are you sure you want to delete this exercise?")}
            </p>
            <div className="flex justify-end space-x-4">
              <NeoBrutalistButton onClick={cancelDelete} style="secondary">
                {t("Cancel")}
              </NeoBrutalistButton>
              <NeoBrutalistButton
                onClick={confirmDelete}
                style="error"
                ref={deleteButtonRef} // Attach ref to the delete button
              >
                {t("Delete")}
              </NeoBrutalistButton>
            </div>
          </div>
        </div>
      )}
      {isEditing && editingExercise && (
        <ExerciseEditor
          exercise={editingExercise}
          onSave={handleSaveEdit}
          onClose={handleCloseEdit}
        />
      )}
      <SendExerciseModal
        isOpen={sendModalOpen}
        onClose={handleCloseSendModal}
        exercise={selectedExercise}
        onExerciseSent={handleExerciseSent}
      />
      <ExerciseCorrection
        isOpen={isCorrecting}
        onClose={() => setIsCorrecting(false)}
        exercise={correctingExercise}
        onAllCorrected={handleAllCorrected}
        onAutoCorrected={handleAutoCorrected}
      />
      {showNotification && (
        <NeoBrutalistNotification
          message={notificationMessage}
          onClose={() => setShowNotification(false)}
        />
      )}
    </div>
    <Footer/>
    </>
  );
};

export default MyExercisesPage;
