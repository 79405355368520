import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import Footer from "../components/Footer";
import NeoBrutalistInput from "../components/NeoBrutalistInput";
import NeoBrutalistTextArea from "../components/NeoBrutalistTextArea";
import NeoBrutalistButton from "../components/NeoBrutalistButton";
import NeoBrutalistNotification from "../components/NeoBrutalistNotification";
import {
  MailIcon,
  PhoneIcon,
  LocationMarkerIcon,
} from "@heroicons/react/solid";
import axios from "axios";
import { FaPlane } from "react-icons/fa";

const ContactPage = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notification, setNotification] = useState(null);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors({});

    const newErrors = {};
    if (!formData.email.trim()) newErrors.email = t("Email is required");
    if (!formData.message.trim()) newErrors.message = t("Message is required");

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsSubmitting(false);
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/contact`, formData);
      setNotification({
        type: "success",
        message: t("Your message has been sent successfully!"),
      });
      setFormData({ name: "", email: "", phone: "", message: "" });
    } catch (error) {
      console.error("Error sending message:", error);
      setNotification({
        type: "error",
        message: t("An error occurred. Please try again later."),
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-yellow-100 flex flex-col">
      <Header />
      <main className="flex-grow container mx-auto px-4 py-8">
        <h1 className="text-6xl font-bold mb-8 text-center text-black transform -rotate-2">
          <span className="bg-red-500 text-white px-4 py-2 inline-block transform rotate-2">
            {t("Contact Us")}
          </span>
        </h1>

        <div className=" gap-8 max-w-3xl mx-auto pb-8">
          <div className="bg-white border-4 border-black p-6 shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
            <h2 className="text-3xl font-bold mb-4">{t("Get in Touch")}</h2>
            <form onSubmit={handleSubmit}>
              <NeoBrutalistInput
                label={t("Name")}
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
              <NeoBrutalistInput
                label={t("Email")}
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                error={errors.email}
                required
              />
              <NeoBrutalistInput
                label={t("Phone")}
                name="phone"
                type="tel"
                value={formData.phone}
                onChange={handleInputChange}
              />
              <NeoBrutalistTextArea
                label={t("Message")}
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                error={errors.message}
                required
              />
              <div className="flex justify-end">
                <NeoBrutalistButton type="submit" disabled={isSubmitting}>
                  <FaPlane className="mr-2" />
                  {isSubmitting ? t("Sending...") : t("Send Message")}
                </NeoBrutalistButton>
              </div>
            </form>
          </div>

          {/* <div className="bg-white border-4 border-black p-6 shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
            <h2 className="text-3xl font-bold mb-4">{t('Contact Information')}</h2>
            <div className="space-y-4">
              <div className="flex items-center">
                <MailIcon className="h-6 w-6 mr-2 text-red-500" />
                <span>contact@example.com</span>
              </div>
              <div className="flex items-center">
                <PhoneIcon className="h-6 w-6 mr-2 text-red-500" />
                <span>+1 234 567 890</span>
              </div>
              <div className="flex items-center">
                <LocationMarkerIcon className="h-6 w-6 mr-2 text-red-500" />
                <span>123 Neo Street, Brutalist City, 12345</span>
              </div>
            </div>
            <div className="mt-8">
              <h3 className="text-2xl font-bold mb-2">{t('Office Hours')}</h3>
              <p>{t('Monday - Friday: 9:00 AM - 5:00 PM')}</p>
              <p>{t('Saturday - Sunday: Closed')}</p>
            </div>
          </div> */}
        </div>
      </main>
      <Footer />
      {notification && (
        <NeoBrutalistNotification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}
    </div>
  );
};

export default ContactPage;
