import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom'; // Add Navigate import
import Header from '../components/Header';
import Footer from '../components/Footer';
import NeoBrutalistButton from '../components/NeoBrutalistButton';
import { LightningBoltIcon, MailIcon, CheckCircleIcon, BriefcaseIcon, ChartBarIcon, GlobeIcon, LoginIcon, UserIcon } from '@heroicons/react/solid';
import { FaRocket } from 'react-icons/fa';
import { useAuth } from "../contexts/AuthProvider";

const HomePage = () => {
  const { t } = useTranslation();
  const { isAuthenticated, logout } = useAuth();


  const features = [
    { icon: <LightningBoltIcon className="w-12 h-12" />, title: t('AI-Powered Exercise Creation'), description: t('Generate tailored, educational exercises across all subjects and academic levels, featuring multiple-choice and open-ended questions.') },
    { icon: <MailIcon className="w-12 h-12" />, title: t('Automatic Distribution'), description: t('Send exercises to students via email with unique links. Receive notifications upon completion.') },
    { icon: <CheckCircleIcon className="w-12 h-12" />, title: t('Easy Correction'), description: t('Quickly and easily correct exercises completed by your students through our intuitive interface.') },
    { icon: <BriefcaseIcon className="w-12 h-12" />, title: t('PDF Exercise Generation'), description: t('Generate exercises and correction instructions in PDF format for easy distribution and printing.') },
    { icon: <ChartBarIcon className="w-12 h-12" />, title: t('Grade Tracking'), description: t('Monitor your students\' results in each subject over time and export these results to CSV.') },
    { icon: <GlobeIcon className="w-12 h-12" />, title: t('Multilingual'), description: t('The application can generate exercises in English, French, Spanish, German, Italian, Portuguese, Dutch, Russian, Chinese, Japanese, and Korean.') },
  ];

  return (
    <div className="min-h-screen bg-yellow-100 flex flex-col">
      <Header />
      <main className="flex-grow container mx-auto px-4 py-8">
        <section className="text-center mb-16 py-8 pt-16">
          <h1 className="text-4xl md:text-6xl font-bold mb-4 text-black transform -rotate-2">
            <span className="bg-red-500 text-white px-4 py-2 inline-block transform rotate-2">
              {t('Revolutionize')}
            </span> {t('Your Teaching')}
          </h1>
          <p className="text-2xl mb-8 font-bold text-gray-800">
            {t('The best way to create, distribute and correct exercises with AI')}
          </p>
          <div className="flex justify-center">
            <NeoBrutalistButton to="/register" size="large" className="transform rotate-2 hover:rotate-0 transition-transform">
            <FaRocket className="mr-2" />
              {t('Get Started for Free')}
            </NeoBrutalistButton>
            </div>
        </section>

      
        <section className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <div key={index} className="bg-white border-4 border-black p-6 shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] transform hover:-translate-y-2 transition-transform">
              <div className="mb-4 text-red-500">{feature.icon}</div>
              <h3 className="text-2xl font-bold mb-2">{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </section>
{/* 
        <section className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-8 text-black transform -rotate-1">
            <span className="bg-blue-500 text-white px-4 py-2 inline-block transform rotate-1">
              {t('Ready to Transform')}
            </span> {t('Your Teaching Experience?')}
          </h2>
          <div className="flex justify-center">
        
              <NeoBrutalistButton to={isAuthenticated?"/generator":"/register"} size="large" style="secondary" className="transform -rotate-1 hover:rotate-0 transition-transform">
                <FaRocket className="mr-2" />
                {t('Join Now')}
              </NeoBrutalistButton>
            
            </div>
        </section> */}

          {/* New section added for the beta announcement */}
          <section className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 text-black transform -rotate-1">
          <span className="bg-blue-500 text-white px-4 py-2 inline-block transform -rotate-1">
          {t('No Subscription Needed During Beta!')} 
          </span>
          </h2>
          <p className="text-xl mb-8 text-gray-800 max-w-4xl mx-auto">
            {t('Our web application is completely free to use during the beta phase. No subscription or credit card required! Simply enter your OpenAI API key, and you will only be billed by OpenAI for the tokens used when generating exercises.')}
          </p>
          <div className="flex justify-center">
        
              <NeoBrutalistButton to={isAuthenticated?"/generator":"/register"} size="large" style="secondary" className="transform -rotate-1 hover:rotate-0 transition-transform">
                <FaRocket className="mr-2" />
                {t('Join Now')}
              </NeoBrutalistButton>
            
            </div>
        </section>



      </main>
      <Footer />
    </div>
  );
};

export default HomePage;