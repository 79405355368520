import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Header from '../components/Header';
import NeoBrutalistButton from '../components/NeoBrutalistButton';
import NeoBrutalistInput from '../components/NeoBrutalistInput';
import NeoBrutalistModal from '../components/NeoBrutalistModal';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { CheckCircleIcon, PencilAltIcon, PlusIcon, SaveAsIcon, SaveIcon, TrashIcon } from '@heroicons/react/solid';
import StudentGradesChart from '../components/StudentGradesChart';
import { axiosInstance } from '../axiosConfig';
import { DownloadIcon } from '@heroicons/react/outline';
import Footer from '../components/Footer';
import logger from '../utils/logger';



const MyStudentsPage = () => {
  const { t } = useTranslation();
  const [students, setStudents] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [newStudent, setNewStudent] = useState({ firstName: '', email: '' });
  const [stats, setStats] = useState([]);
  const [selectedStudentGrades, setSelectedStudentGrades] = useState([]);
  
   const chartContainerRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);
  useEffect(() => {
    if (chartContainerRef.current) {
      setChartWidth(chartContainerRef.current.offsetWidth-48);
    }
  }, []);

  useEffect(() => {
    const updateChartWidth = () => {
      if (chartContainerRef.current) {
        setChartWidth(chartContainerRef.current.offsetWidth - 32); // Reduced padding
      }
    };

    updateChartWidth();
    window.addEventListener('resize', updateChartWidth);

    return () => window.removeEventListener('resize', updateChartWidth);
  }, []);

  useEffect(() => {
    fetchStudents();
    fetchStats();
  }, []);

  const fetchStudents = async () => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/students`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setStudents(response.data.students);
    } catch (error) {
      logger.error("Erreur lors de la récupération des étudiants:", error);
    }
  };

  const fetchStats = async () => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/students/stats`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        params: { since: 'last_september' }
      });
      setStats(response.data);
    } catch (error) {
      logger.error("Erreur lors de la récupération des statistiques:", error);
    }
  };

  const handleAddStudent = async () => {
    try {
      await axiosInstance.post(`${process.env.REACT_APP_API_URL}/students/invite`, newStudent, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setIsAddModalOpen(false);
      setNewStudent({ firstName: '', email: '' });
      fetchStudents();
    } catch (error) {
      logger.error("Erreur lors de l'ajout de l'étudiant:", error);
    }
  };

  const handleSaveStudent = async () => {
    try {
      await axiosInstance.put(`${process.env.REACT_APP_API_URL}/students/${selectedStudent._id}`, selectedStudent, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setIsEditModalOpen(false);
     

      setSelectedStudent(null);
      fetchStudents();
    } catch (error) {
      logger.error("Erreur lors de la modification de l'étudiant:", error);
    }
  };

  const fetchStudentGrades = async (studentId) => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/students/${studentId}/grades`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        params: { since: 'last_september' }
      });
      return response.data;
    } catch (error) {
      logger.error("Erreur lors de la récupération des notes de l'étudiant:", error);
      return [];
    }
  };

  const handleDeleteStudent = async (id) => {
    if (window.confirm(t('Are you sure you want to delete this student?'))) {
      try {
        await axiosInstance.delete(`${process.env.REACT_APP_API_URL}/students/${id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        fetchStudents();
      } catch (error) {
        logger.error("Erreur lors de la suppression de l'étudiant:", error);
      }
    }
  };

  const handleEditStudent = async (student) => {
    setSelectedStudent(student);
    const grades = await fetchStudentGrades(student._id);
    logger.log('grades', grades);
    setSelectedStudentGrades(grades);
    setIsEditModalOpen(true);
  };

  const handleExportCSV = async () => {
    try {
      const studentsWithGrades = await Promise.all(students.map(async (student) => {
        const grades = await fetchStudentGrades(student._id);
        return { ...student, grades };
      }));

      const csvContent = "data:text/csv;charset=utf-8," 
        + "First Name,Email,Subject,Date,Grade\n"
        + studentsWithGrades.map(student => 
            student.grades.map(grade => 
              `${student.firstName},${student.email},${grade.subject},${grade.date},${grade.score}`
            ).join("\n")
          ).join("\n");

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "students_with_grades.csv");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      logger.error("Erreur lors de l'exportation des données CSV:", error);
    }
  };



  return (
    <>
    <div className="min-h-screen bg-gray-200">
      <Header activeLink="my-students" />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-8 text-center text-black border-b-4 border-black pb-2">
          {t('My Students')}
        </h1>
        
        <div className="flex justify-center md:justify-end mb-4 flex-col md:flex-row md:space-x-4">
          <NeoBrutalistButton onClick={handleExportCSV} className="mb-4 " style="secondary">
            <DownloadIcon className="w-4 h-4 mr-2" />
            {t('Export as CSV')}
          </NeoBrutalistButton>
          <NeoBrutalistButton onClick={() => setIsAddModalOpen(true)} className="mb-4">
            <PlusIcon className="w-4 h-4 mr-2" />
            {t('Add Student')}
          </NeoBrutalistButton>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {students.map((student) => (
            <div key={student._id} className="bg-white border-4 border-black p-4 shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
              <h2 className="text-xl font-bold">{student.firstName}</h2>
              <p>{student.email}</p>
              <div className="mt-2 flex justify-end gap-2">
                <NeoBrutalistButton onClick={() => handleDeleteStudent(student._id)} style="secondary" size='small'>
                    <TrashIcon className="w-4 h-4" />
                  {t('Delete')}
                </NeoBrutalistButton>
                <NeoBrutalistButton onClick={() => { handleEditStudent(student);  }} className="mr-2" size='small'>
                  <PencilAltIcon className="w-4 h-4" />
                  {t('Edit')}
                </NeoBrutalistButton>
              </div>
            </div>
          ))}
        </div>

        <h2 className="text-3xl font-bold mt-12 mb-4">{t('Student Performance Statistics')}</h2>
        <div className="bg-white border-4 border-black p-4 shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]" ref={chartContainerRef}>
 {chartWidth > 0 && (
            <LineChart width={chartWidth} height={400} data={Object.values(stats)[0]}>
          
                   <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis domain={[0, 'dataMax']} />
              <Tooltip />
              <Legend />
              {Object.entries(stats).map(([studentId, studentData], index) => (
                <Line 
                  key={studentId}
                  type="monotone" 
                  dataKey="score" 
                  data={studentData}
                  name={studentData[0]?.studentName || `Student ${index + 1}`}
                  stroke={`#${Math.floor(Math.random()*16777215).toString(16)}`} 
                  strokeWidth={3}
                />
              ))}
            </LineChart>
          )}
</div>

        <NeoBrutalistModal isOpen={isAddModalOpen} onClose={() => setIsAddModalOpen(false)} title={t('Add Student')}>
          <NeoBrutalistInput
            label={t('First Name')}
            value={newStudent.firstName}
            onChange={(e) => setNewStudent({ ...newStudent, firstName: e.target.value })}
          />
          <NeoBrutalistInput
            label={t('Email')}
            value={newStudent.email}
            onChange={(e) => setNewStudent({ ...newStudent, email: e.target.value })}
          />
          <NeoBrutalistButton onClick={handleAddStudent}>{t('Add')}</NeoBrutalistButton>
        </NeoBrutalistModal>

        <NeoBrutalistModal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} title={t('Edit Student')}>
  <NeoBrutalistInput
    label={t('First Name')}
    value={selectedStudent?.firstName || ''}
    onChange={(e) => setSelectedStudent({ ...selectedStudent, firstName: e.target.value })}
  />
  <NeoBrutalistInput
    label={t('Email')}
    value={selectedStudent?.email || ''}
    onChange={(e) => setSelectedStudent({ ...selectedStudent, email: e.target.value })}
  />
  <div className="flex justify-end mb-4">
    <NeoBrutalistButton onClick={handleSaveStudent}>
      <CheckCircleIcon className="w-4 h-4 mr-2" />
      {t('Save')}
    </NeoBrutalistButton>
  </div>
  <h3 className="text-xl font-bold mb-4">{t('Student Grades by Subject')}</h3>
  <StudentGradesChart grades={selectedStudentGrades} />
</NeoBrutalistModal>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default MyStudentsPage;