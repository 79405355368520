import React from 'react';
import { useNavigate } from 'react-router-dom';

const Logo = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div className="relative flex items-center hover:opacity-80 transition-all z-50" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
      <img src="/img/email_logo.png" alt="logo" className="max-h-16 mr-4" />
      <span className="absolute top-2 right-0 bg-yellow-400 text-black text-xs font-bold py-1 px-2 rounded-lg transform rotate-12 translate-x-1/2 -translate-y-1/2">
        BETA
      </span>
    </div>
  );
};

export default Logo;