import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import NeoBrutalistButton from "./NeoBrutalistButton";
import HeaderLink from "./HeaderLink";
import { axiosInstance } from "../axiosConfig";
import { useTranslation } from "react-i18next";
import Logo from "./Logo";
import { useAuth } from "../contexts/AuthProvider";
import { LogoutIcon } from "@heroicons/react/solid";
import NeoBrutalistNotification from "./NeoBrutalistNotification";

const Header = ({ activeLink }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { isAuthenticated, logout } = useAuth();

  const [userName, setUserName] = useState("");
  const [plan, setPlan] = useState("");
  const [exercisesLeft, setExercisesLeft] = useState(0);
  const [correctionsLeft, setCorrectionsLeft] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

 
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     fetchMyPlanId();
  //     fetchRemainingCredits();
  //   }
  // }, [isAuthenticated]);

  const fetchMyPlanId = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/subscriptions/getMyPlanId`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setPlan(response.data.plan);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération du plan de l'utilisateur:",
        error
      );
    }
  };

  const fetchRemainingCredits = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/subscriptions/getRemainingCredits`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setExercisesLeft(response.data.exercisesLeft);
      setCorrectionsLeft(response.data.correctionsLeft);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des crédits restant:",
        error
      );
    }
  };
  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <header className="bg-white border-b-4 border-black md:shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] p-4 flex justify-between items-center">
        <div className="flex items-center flex-1">
          <Logo />
        </div>
        {isAuthenticated && (
          <>
            <nav className="hidden lg:flex space-x-4 flex-2">
              <HeaderLink
                to="/generator"
                active={activeLink === "generator"}
                className="w-full md:w-auto mb-2 md:mb-0 hover:bg-yellow-400 hover:border-b-4 hover:border-black transition-all duration-200"
              >
                {t("Generator")}
              </HeaderLink>
              <HeaderLink
                to="/my-exercises"
                active={activeLink === "my-exercises"}
                className="w-full md:w-auto mb-2 md:mb-0 hover:bg-yellow-400 hover:border-b-4 hover:border-black transition-all duration-200"
              >
                {t("My Exercises")}
              </HeaderLink>
              <HeaderLink
                to="/my-students"
                active={activeLink === "my-students"}
                className="hover:bg-yellow-400 hover:border-b-4 hover:border-black transition-all duration-200"
              >
                {t("My Students")}
              </HeaderLink>
              <HeaderLink
                to="/settings"
                active={activeLink === "settings"}
                className="w-full md:w-auto mb-2 md:mb-0 hover:bg-yellow-400 hover:border-b-4 hover:border-black transition-all duration-200"
              >
                {t("Settings")}
              </HeaderLink>
            </nav>
            <div className="flex-1 text-end flex items-center justify-end space-x-4">
              <div className="text-sm">
                <p className="hidden md:block">{userName}</p>
                <div className="flex space-x-2 ml-4 justify-end">
                  <div className="flex flex-col space-y-0 pt-1">
                    <img
                      src="/img/icons/usa.png"
                      alt="English"
                      className="h-6 cursor-pointer hover:scale-110 transition-all duration-300"
                      onClick={() => changeLanguage("en")}
                    />
                    <img
                      src="/img/icons/france.png"
                      alt="Français"
                      className="h-6 cursor-pointer hover:scale-110 transition-all duration-300"
                      onClick={() => changeLanguage("fr")}
                    />
                  </div>
                </div>
                {/* <p className="mr-2">
                {plan !== "" ? t("Exercises left") + ":" + exercisesLeft : null}
              </p>
              {plan !== "" && plan !== "free" && plan !== "basic" && (
                <p className="mr-2">
                  {t("Auto-corrections left")}: {correctionsLeft}
                </p>
              )} */}
              </div>
              <NeoBrutalistButton
                onClick={handleLogout}
                className="float-end hidden lg:block"
              >
                <span className="hidden xl:inline">{t("Log Out")}</span>
                <span className="xl:hidden">
                  <LogoutIcon className="w-6 h-6" />
                </span>
              </NeoBrutalistButton>
            </div>

            <button
              className="lg:hidden text-black p-2 ml-2 text-2xl"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              ☰
            </button>
          </>
        )}
        {!isAuthenticated && (
          <div className="flex space-x-2 ml-4 justify-end">
            <div className="flex flex-col space-y-1 pt-1">
              <img
                src="/img/icons/usa.png"
                alt="English"
                className="h-6 cursor-pointer hover:scale-110 transition-all duration-300"
                onClick={() => changeLanguage("en")}
              />
              <img
                src="/img/icons/france.png"
                alt="Français"
                className="h-6 cursor-pointer hover:scale-110 transition-all duration-300"
                onClick={() => changeLanguage("fr")}
              />
            </div>
            <NeoBrutalistButton to="/login" className="text-black">
              {t("Log In")}
            </NeoBrutalistButton>
          </div>
        )}

        {/* <button
        className="md:hidden text-black"
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      >
        ☰
      </button> */}
        {isMobileMenuOpen && (
          <div className="absolute top-0 left-0 w-full h-full bg-white border-t-4 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] p-4 flex flex-col items-center space-y-4 z-50">
            <button
              className="self-end text-black"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              ✕
            </button>
            <HeaderLink
              to="/generator"
              active={activeLink === "generator"}
              className="w-full text-center hover:bg-yellow-400 hover:border-b-4 hover:border-black transition-all duration-200"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              {t("Generator")}
            </HeaderLink>
            <HeaderLink
              to="/my-exercises"
              active={activeLink === "my-exercises"}
              className="w-full text-center hover:bg-yellow-400 hover:border-b-4 hover:border-black transition-all duration-200"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              {t("My Exercises")}
            </HeaderLink>
            <HeaderLink
              to="/my-students"
              active={activeLink === "my-students"}
              className="w-full text-center hover:bg-yellow-400 hover:border-b-4 hover:border-black transition-all duration-200"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              {t("My Students")}
            </HeaderLink>
            <HeaderLink
              to="/settings"
              active={activeLink === "settings"}
              className="w-full text-center hover:bg-yellow-400 hover:border-b-4 hover:border-black transition-all duration-200"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              {t("Settings")}
            </HeaderLink>
            <HeaderLink
              to="#"
              className="w-full text-center hover:bg-yellow-400 hover:border-b-4 hover:border-black transition-all duration-200"
              onClick={() => {
                handleLogout();
                setIsMobileMenuOpen(false);
              }}
            >
              {t("Log Out")}
            </HeaderLink>
          </div>
        )}
      </header>
      {/* {isAuthenticated && plan !== "" && (
        <div className="relative">
          <div className="relative md:absolute md:right-0 top-2 z-10 bg-gray-600 text-white p-3 w-full md:w-auto mb-4 md:rounded-tl-lg md:rounded-bl-lg shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
            <div className="flex space-x-4 justify-center">
              <p>
                {t("Credits left")}: {exercisesLeft}
              </p>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default Header;
