import React, { useState, useEffect } from "react";
import { useTranslation, useLanguage } from "react-i18next";
import {
  AcademicCapIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import NeoBrutalistInput from "../components/NeoBrutalistInput.js";
import NeoBrutalistSelect from "../components/NeoBrutalistSelect";
import NeoBrutalistTextArea from "../components/NeoBrutalistTextArea";
import NeoBrutalistButton from "../components/NeoBrutalistButton";
import NeoBrutalistNotification from "../components/NeoBrutalistNotification";
import axios from "axios";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header.js";
import { axiosInstance } from "../axiosConfig";
import Footer from "../components/Footer.js";
import logger from "../utils/logger";
import OpenAIKeyModal from '../components/OpenAIKeyModal';


const GeneratorPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [remainingExercises, setRemainingExercises] = useState(null);
  const [exerciseData, setExerciseData] = useState({
    subject: "",
    instructions: "", // Replaced 'description' with 'instructions'
    level: "middle_school",
    tone: "Neutral",
    type: "multiple_choice",
    language: i18n.language== 'fr' ? 'french' : 'english',
    numberOfQuestions: 3,
    generateLesson: false,
    // educationalObjective: '',
  });
  const [showNotification, setShowNotification] = useState(false);
  const [showOpenAIKeyModal, setShowOpenAIKeyModal] = useState(false);
const [freeGenerationsLeft, setFreeGenerationsLeft] = useState(5);
const [notificationMessage, setNotificationMessage] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      
    fetchRemainingCredits();
    }
  }, []);

  const handleSaveOpenAIKey = async (key) => {
    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/settings/updateOpenAIKey`, { openaiApiKey: key });
      if (response.data.isValid) {
        setShowOpenAIKeyModal(false);
        fetchRemainingCredits();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la clé OpenAI:", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        openaiKey: t("Error updating OpenAI key")
      }));
      return false;
    }
  };
  
  const fetchRemainingCredits = async () => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/subscriptions/getRemainingCredits`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setFreeGenerationsLeft(response.data.freeGenerationsLeft);
      if (!response.data.hasOpenAIKey) {
        setShowOpenAIKeyModal(true);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des crédits restants:", error);
    }
  };

  // const fetchRemainingCredits = async () => {
  //   try {
  //     const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/subscriptions/getRemainingCredits`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     });
  //     setRemainingExercises(response.data.exercisesLeft);
  //   } catch (error) {
  //     logger.error("Erreur lors de la récupération des crédits restants:", error);
  //   }
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setExerciseData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleNext = () => {
    const newErrors = {};
    logger.log("step", step);
    if (step === 1) {
      if (!exerciseData.subject.trim())
        newErrors.subject = t("Subject is required");
      /* if (!exerciseData.instructions.trim())
        newErrors.instructions = t("Instructions is required");*/
      if (!exerciseData.level) newErrors.level = t("Level is required");
    }
    if (Object.keys(newErrors).length === 0) {
      if (step < 2) {
        // Vérifiez que nous ne sommes pas à la dernière étape
        setStep((prevStep) => prevStep + 1);
      }
      setErrors({});
    } else {
      setErrors(newErrors);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    logger.log("handleSubmit");
    if (!acceptTerms) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        terms: t("You must accept the terms and conditions"),
      }));
      return;
    }
    // if (remainingExercises !== null && remainingExercises <= 0) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     subscriptionLimit: t("You have reached your exercise generation limit. Please upgrade your plan or fill your OpenAI API key in the settings.")
    //   }));
    // setShowNotification(true);
    //   return;
    // }

    const newErrors = {};
    if (!exerciseData.tone) newErrors.tone = t("Tone is required");
    if (!exerciseData.type) newErrors.type = t("Type is required");
    if (!exerciseData.language) newErrors.language = t("Language is required");

    if (Object.keys(newErrors).length === 0) {
      setIsLoading(true);
      axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}/exercises/createExercise`,
          {
            ...exerciseData,
            numberOfQuestions: exerciseData.numberOfQuestions || undefined,
            generateLesson: exerciseData.generateLesson,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          logger.log("Exercice créé avec succès:", response.data);
          setIsLoading(false);
          // Rediriger vers MyExercises avec l'ID du nouvel exercice
          navigate("/my-exercises", {
            state: { newExerciseId: response.data._id },
          });
        })
        .catch((error) => {
          console.log('error', error);
          setIsLoading(false);
          setShowNotification(true);
          setNotificationMessage(t("An error occurred. Please check if your OpenAI API key is valid."));
        });
    } else {
      setErrors(newErrors);
    }
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-6">
            <NeoBrutalistSelect
              label={t("Subject")}
              name="subject"
              error={errors.subject}
              value={exerciseData.subject}
              onChange={handleInputChange}
              options={JSON.parse(process.env.REACT_APP_SUBJECTS).map(subject => ({ value: subject, label: t(subject) }))}
            />
            <NeoBrutalistTextArea
              label={t("Instructions")} // Updated 'Description' to 'Instructions'
              name="instructions" // Updated 'description' to 'instructions'
              error={errors.instructions} // Updated 'description' to 'instructions'
              value={exerciseData.instructions} // Updated 'description' to 'instructions'
              onChange={handleInputChange}
            />
            <NeoBrutalistSelect
              label={t("Level")}
              name="level"
              value={exerciseData.level}
              error={errors.level}
              onChange={handleInputChange}
              options={JSON.parse(process.env.REACT_APP_LEVELS).map(level => ({ value: level.toLowerCase().replace(/ /g, "_"), label: t(level) }))}
            />
            
          </div>
        );
      case 2:
        return (
          <div className="space-y-6">
            

            {/* <NeoBrutalistInput
            label={t('Educational Objective')}
            name="educationalObjective"
            error={errors.educationalObjective}
            value={exerciseData.educationalObjective}
            onChange={handleInputChange}
          /> */}
            <NeoBrutalistSelect
              label={t("Type")}
              name="type"
              value={exerciseData.type}
              onChange={handleInputChange}
              labelOption={false}
              options={[
                { value: "multiple_choice", label: t("Multiple Choice") },
                { value: "fill_in_the_blanks", label: t("Fill in the blanks") },
                { value: "free_writing", label: t("Free Writing") },
                { value: "matching", label: t("Matching") }
              ]}
            />
            <NeoBrutalistSelect
              label={t("Language")}
              name="language"
              value={exerciseData.language}
              onChange={handleInputChange}
              labelOption={false}
              options={[
                { value: "english", label: t("English") },
                { value: "french", label: t("French") },
                { value: "spanish", label: t("Spanish") },
                { value: "german", label: t("German") },
                { value: "italian", label: t("Italian") },
                { value: "portuguese", label: t("Portuguese") },
                { value: "dutch", label: t("Dutch") },
                { value: "russian", label: t("Russian") },
                { value: "chinese", label: t("Chinese") },
                { value: "japanese", label: t("Japanese") },
                { value: "korean", label: t("Korean") },
              ]}
            />
            <NeoBrutalistInput
              label={t("Number of Questions")}
              name="numberOfQuestions"
              type="number"
              value={exerciseData.numberOfQuestions}
              onChange={handleInputChange}
              placeholder={t("Leave empty for automatic")}
            />
            <NeoBrutalistSelect
              label={t("Tone")}
              name="tone"
              value={exerciseData.tone}
              onChange={handleInputChange}
              labelOption={false}
              options={JSON.parse(process.env.REACT_APP_TONES).map(tone => ({ value: tone, label: t(tone) }))}
            />
            {/* <div className="flex items-center mt-4">
              <input
                type="checkbox"
                id="generateLesson"
                name="generateLesson"
                checked={exerciseData.generateLesson}
                onChange={(e) =>
                  setExerciseData((prev) => ({
                    ...prev,
                    generateLesson: e.target.checked,
                  }))
                }
                className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="generateLesson"
                className="ml-2 text-lg font-medium text-gray-900 dark:text-gray-300"
              >
                {t("Generate a resume of the corresponding lesson")}
              </label>
            </div> */}
            <div className="flex items-center mt-4 mb-6">
              <input
                type="checkbox"
                id="acceptTerms"
                checked={acceptTerms}
                onChange={(e) => setAcceptTerms(e.target.checked)}
                className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="acceptTerms"
                className="ml-2 text-lg font-medium text-black"
              >
                {t("I accept the")}{" "}
                <a href="/terms" target="_blank" className="text-blue-600 hover:underline">
                  {t("terms and conditions")}
                </a>
              </label>
            </div>

            {errors.terms && (
              <p className="text-red-500 mt-2 font-bold text-center">
                {errors.terms}
              </p>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
        <div className="min-h-screen bg-gray-100 flex flex-col ">
      <Header activeLink="generator" />
      <div className="flex-1 bg-gray-200 flex items-start justify-center py-12 px-4 sm:px-6 lg:px-8 ">
        <div className="max-w-lg w-full space-y-8 bg-white p-10 rounded-lg shadow-2xl border-4 border-black">
          {isLoading ? (
            <Loader
              title={t("Generating exercise")}
              description={t(
                "Please wait while our awesome A.I generates your exercise"
              )}
            />
          ) : (
            <>
              <h2 className="text-center text-3xl font-extrabold text-gray-900 mb-6 border-b-4 border-yellow-400">
                {t("Generate an exercise")}
              </h2>
              <form onSubmit={handleSubmit} className="mt-8 space-y-6">
                {renderStep()}
                <div className="flex justify-between mt-8 gap-4">
                  {step > 1 ? (
                    <NeoBrutalistButton
                      onClick={handlePrevious}
                      type="button"
                      style="secondary"
                    >
                      <ChevronLeftIcon className="h-5 w-5 mr-2" />
                      <span className="hidden md:inline">
                      {t("Previous")}
                      </span>
                    </NeoBrutalistButton>
                  ) : (
                    <div className="flex-1"></div>
                  )}
                  {step < 2 ? (
                    <NeoBrutalistButton onClick={handleNext} type="button">
                      {t("Next")}
                      <ChevronRightIcon className="h-5 w-5 ml-2" />
                    </NeoBrutalistButton>
                  ) : (
                    <>
                      <NeoBrutalistButton type="submit">
                        <AcademicCapIcon className="h-5 w-5 mr-2" />
                        {t("Generate")}
                      </NeoBrutalistButton>
                    </>
                  )}
                </div>
              </form>
            </>
          )}
        </div>
      </div>
      <Footer/>
      </div>
      {/* <NeoBrutalistNotification
      isOpen={showNotification}
      onClose={() => setShowNotification(false)}
      message={t("You have reached your exercise generation limit. Please upgrade your plan or fill your OpenAI API key in the settings.")}
    /> */}
    <OpenAIKeyModal
      isOpen={showOpenAIKeyModal}
      onClose={() => setShowOpenAIKeyModal(false)}
      onSave={handleSaveOpenAIKey}
      freeGenerationsLeft={freeGenerationsLeft}
    />
    <NeoBrutalistNotification
  isOpen={showNotification}
  onClose={() => setShowNotification(false)}
  message={notificationMessage}
/>
    </>
  );
};

export default GeneratorPage;
