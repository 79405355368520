import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PrivacyPage = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header />
      <main className="container mx-auto px-4 py-8 max-w-4xl">
        <h1 className="text-3xl font-bold mb-6">{t('Privacy Policy')}</h1>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">{t('1. Information Collection')}</h2>
          <p className="mb-4">
            {t('We collect information when you register on our site, log into your account, create exercises, or respond to exercises. The information collected includes your name, email address, and, if applicable, data related to the exercises created or completed.')}
          </p>

          <h2 className="text-2xl font-semibold mb-4">{t('2. Use of Information')}</h2>
          <p className="mb-4">
            {t('The information we collect is used to:')}
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>{t('Personalize your experience and respond to your individual needs')}</li>
            <li>{t('Provide personalized advertising content')}</li>
            <li>{t('Improve our website')}</li>
            <li>{t('Enhance customer service and your support needs')}</li>
            <li>{t('Contact you via email')}</li>
          </ul>

          <h2 className="text-2xl font-semibold mb-4">{t('3. Online Privacy')}</h2>
          <p className="mb-4">
            {t('We are the sole owners of the information collected on this site. Your personal information will not be sold, exchanged, transferred, or given to any other company for any reason without your consent, except as necessary to fulfill a request and/or transaction (for example, for shipping an order).')}
          </p>

          <h2 className="text-2xl font-semibold mb-4">{t('4. Disclosure to Third Parties')}</h2>
          <p className="mb-4">
            {t('We do not sell, trade, or transfer your personally identifiable information to third parties. This does not include trusted third parties who assist us in operating our website or conducting our business, as long as those parties agree to keep this information confidential.')}
          </p>

          <h2 className="text-2xl font-semibold mb-4">{t('5. Information Protection')}</h2>
          <p className="mb-4">
            {t('We implement a variety of security measures to maintain the safety of your personal information. We use advanced encryption to protect sensitive information transmitted online.')}
          </p>

          <h2 className="text-2xl font-semibold mb-4">{t('6. Right of Access, Rectification, and Deletion')}</h2>
          <p className="mb-4">
            {t('In accordance with the French Data Protection Act of January 6, 1978, as amended, and the General Data Protection Regulation (GDPR), you have the right to access, rectify, and delete your personal data. You can exercise this right by contacting us via our contact page or by mail at our postal address.')}
          </p>

          <h2 className="text-2xl font-semibold mb-4">{t('7. Consent')}</h2>
          <p className="mb-4">
            {t('By using our site, you consent to our privacy policy.')}
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default PrivacyPage;