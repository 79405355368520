import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import RegistrationPage from "./pages/RegistrationPage";
import GeneratorPage from "./pages/GeneratorPage";
import HomePage from "./pages/HomePage";
import MyExercisesPage from "./pages/MyExercisesPage";
import StudentExercisePage from "./pages/StudentExercisePage";
import ProtectedRoute from "./components/ProtectedRoute";
import axios from "axios";
import SettingsPage from "./pages/SettingsPage";
import StudentCorrectionPage from "./pages/StudentCorrectionPage";
import MyStudentsPage from "./pages/MyStudentsPage";
import { useNavigate } from "react-router-dom";
import { setupAxiosInterceptors } from "./axiosConfig";
import SubscriptionSuccessPage from "./pages/SubscriptionSuccessPage";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import TermsPage from "./pages/TermsPage";
import PrivacyPage from "./pages/PrivacyPage";
import ContactPage from "./pages/ContactPage";
import logger from "./utils/logger";
import LegalNoticePage from "./pages/LegalNoticePage";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function AppContent() {
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (
      !["/", "/contact", "/privacy", "/terms"].includes(currentPath) &&
      !currentPath != "/"
    ) {
      setupAxiosInterceptors(navigate);
    }
  }, [navigate]);

  // const verifyToken = async (token) => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}/auth/verify-registration-token?registrationToken=${token}`
  //     );
  //     if (response.data.valid) {
  //       logger.log("Token is valid");
  //       axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  //     } else {
  //       logger.log("Token is invalid");
  //       localStorage.removeItem("token"); // Remove invalid token
  //     }
  //   } catch (error) {
  //     logger.error("Error verifying token:", error);
  //     localStorage.removeItem("token"); // Remove token on error
  //   }
  // };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const currentPath = window.location.pathname;
    if (token && !currentPath.includes("verify-email")) {
      //verifyToken(token);
    }
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/legal" element={<LegalNoticePage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegistrationPage />} />
          <Route
            path="/exercise-reply/:uniqueLink"
            element={<StudentExercisePage />}
          />
          <Route
            path="/correction/:uniqueLink"
            element={<StudentCorrectionPage />}
          />
          <Route
            path="/verify-email/:registrationToken"
            element={<LoginPage />}
          />

          <Route element={<ProtectedRoute />}>
            <Route path="/generator" element={<GeneratorPage />} />
            <Route path="/my-exercises" element={<MyExercisesPage />} />
            <Route path="/my-students" element={<MyStudentsPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route
              path="/subscription-success"
              element={<SubscriptionSuccessPage />}
            />
          </Route>

          {/* <Route path="*" element={<Navigate to="/login" replace />} /> */}
        </Routes>
      </div>
    </Elements>
  );
}

export default AppContent;
