import React, { useState } from 'react';

const NeoBrutalistInput = ({ label, name, value, onChange, error, min, max, type = 'text', inline=false, placeholder='', description='', infoLink='', infoText='' }) => {

  const [showTooltip, setShowTooltip] = useState(false);


  return (
    <div className={`mb-4 ${inline ? 'inline-block' : ''}`}>
      <div className={`flex items-center mb-2 ${inline ? 'inline-block' : ''}`}>
        <label htmlFor={name} className="block text-lg font-bold text-black">
          {label}
        </label>
        <div className="flex-1"></div>
        {(infoLink || infoText) && (
          <div className="relative ml-2">
            <div
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-black cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
              </svg>
            </div>
            {showTooltip && infoText && (
              <div className="absolute z-10 p-2 bg-black text-white text-sm rounded shadow-lg -left-2 top-6 w-48 text-center">
                {infoText}
              </div>
            )}
          </div>
        )}
      </div>
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        min={min}
        max={max}
        placeholder={placeholder}
        onChange={onChange}
        className={` px-4 py-2 text-black bg-white border-4 ${error ? 'border-red-500' : 'border-black'} rounded-none shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] focus:outline-none focus:ring-4 focus:ring-yellow-400 focus:border-black transition-all ${inline ? 'inline-block w-auto' : 'w-full'}`}
      />
      {description && <p className="mt-2 text-black text-sm">{description}</p>}
      {error && <p className="mt-2 text-red-500 text-sm">{error}</p>}
    </div>
  );
};

export default NeoBrutalistInput;