import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import Footer from '../components/Footer';

const LegalNoticePage = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header />
      <main className="container mx-auto px-4 py-8 max-w-4xl">
        <h1 className="text-3xl font-bold mb-6">{t('Legal Notice')}</h1>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">{t('1. Company Information')}</h2>
          <p className="mb-4">
            {t('Company Name')}: {process.env.REACT_APP_COMPANY_NAME}
            <br />
            {t('Address')}: {process.env.REACT_APP_COMPANY_ADDRESS}
            <br />
            {t('Email')}: {process.env.REACT_APP_COMPANY_EMAIL}
          </p>

          <h2 className="text-2xl font-semibold mb-4">{t('2. Website Hosting')}</h2>
          <p className="mb-4">
            {t('This website is hosted by')} {} 
            <br />
            {t('Address')} {process.env.REACT_APP_WEBHOST_ADDRESS}
            <br />
            {t('Phone')} {process.env.REACT_APP_WEBHOST_PHONE}
          </p>

          <h2 className="text-2xl font-semibold mb-4">{t('3. Intellectual Property')}</h2>
          <p className="mb-4">
            {t('All content on this website, including but not limited to text, graphics, logos, images, and software, is the property of {{CompanyName}} and is protected by international copyright laws.', { CompanyName: process.env.REACT_APP_COMPANY_NAME })}
          </p>

          <h2 className="text-2xl font-semibold mb-4">{t('4. Personal Data')}</h2>
          <p className="mb-4">
            {t('For information on how we collect and process personal data, please refer to our Privacy Policy.')}
          </p>

          <h2 className="text-2xl font-semibold mb-4">{t('5. Cookies')}</h2>
          <p className="mb-4">
            {t('This website uses cookies to enhance user experience. By using our website, you consent to our use of cookies in accordance with our Cookie Policy.')}
          </p>

          <h2 className="text-2xl font-semibold mb-4">{t('6. Disclaimer')}</h2>
          <p className="mb-4">
            {t('The information provided on this website is for general informational purposes only. We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability of the information contained on the website.')}
          </p>

          <h2 className="text-2xl font-semibold mb-4">{t('7. Applicable Law')}</h2>
          <p className="mb-4">
            {t('This legal notice is governed by the laws of France. Any disputes relating to this notice will be subject to the exclusive jurisdiction of the courts of France.')}
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default LegalNoticePage;