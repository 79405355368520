import React from 'react';
import NeoBrutalistButton from './NeoBrutalistButton';
import { XIcon } from '@heroicons/react/solid';

const NeoBrutalistModal = ({ isOpen, onClose, title, children, footer, closable=true, size="lg" }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
<div className={`bg-white rounded-lg shadow-lg border-4 border-black w-3/4 ${size === 'xl' ? 'max-w-8xl' : size === 'sm' ? 'max-w-sm' : 'max-w-4xl'} max-h-[90vh] flex flex-col`}>
<div className="p-4 border-b-4 border-black flex justify-between items-center">
          <h2 className="text-2xl font-bold">{title}</h2>
          {closable && (
            <NeoBrutalistButton onClick={onClose} style="secondary" size="small">
              <XIcon className="h-5 w-5" />
            </NeoBrutalistButton>
          )}
        </div>
        
        <div className="p-6 overflow-y-auto flex-grow bg-gray-200">
          {children}
        </div>
        
        {footer && (
          <div className="p-4 border-t-4 border-black">
            {footer}
          </div>
        )}
      </div>
    </div>
  );
};

export default NeoBrutalistModal;